<template>
  <div class="my-qrcode">
    <div class="my-qrcode-wrap">
      <div class="my-qrcode-wrap-div animated fadeIn slower" v-show="isShowQR">
        <img src="./../../assets/img/qrcode_bg.png">
        <div class="my-qrcode-wrap-div-qr">
          <p>Hello baby！请扫二维码进行登录</p>
          <qrcode-vue :value="qrValue" :size="qrSize" level="H"></qrcode-vue>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import QrcodeVue from 'qrcode.vue';
  // eslint-disable-next-line no-unused-vars
  import {mapActions} from 'vuex';
  import {fetchQRInfoUrl, fetchQRResultUrl} from '../../api';

  export default {
    name: 'QRLoginPage',
    data() {
      return {
        isShowQR: false,
        qrValue: '',
        qrSize: 0,
        loginId: '',
        timer: null,
      };
    },
    mounted() {
      this.fetchQRInfo();
      window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
      clearInterval(this.timer);
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      async fetchQRInfo() {
        const loadingInstance1 = this.$loading({
          background: 'rgba(0, 0, 0, 0.7)',
        });
        try {
          const res = await this.$axios.get(fetchQRInfoUrl);
          // eslint-disable-next-line eqeqeq
          if (res.data != undefined) {
            setTimeout(() => {
              this.loginId = res.data.data.loginId;
              this.qrValue = `https://passport.howiech.com?loginId=${this.loginId
                  }`;
              // eslint-disable-next-line no-mixed-operators
              this.handleResize();
              this.isShowQR = true;
              loadingInstance1.close();
            }, 2000);

            this.timer = setInterval(() => {
              this.fetchQRIsOK();
            }, 1000);
          }
        } catch (e) {
          console.log(e)
          loadingInstance1.close();
        }

      },
      async fetchQRIsOK() {
        try {
          // eslint-disable-next-line no-undef
          const res = await this.$axios.get(`${fetchQRResultUrl}?loginId=${
              this.loginId}`);
          // eslint-disable-next-line eqeqeq
          if (res != undefined && res.data.data != undefined && res.data.data.result != undefined) {
            // eslint-disable-next-line eqeqeq
            if (res.data.data.result == true) {
              clearInterval(this.timer);

              if (!this.$utils.isEmpty(res.data.data.nickName)) {
                this.saveUserNameAction(res.data.data.nickName);
              } else {
                this.saveUserNameAction('');
              }
              // eslint-disable-next-line no-unused-expressions
              this.$router.push({path: '/manage'})
                  .catch((err) => {
                    console.log(err);
                  });
            }
          }
        } catch (e) {
          console.log(e)
        }
      },
      // eslint-disable-next-line no-unused-vars
      handleResize(e) {
        // eslint-disable-next-line no-mixed-operators
        this.qrSize = 150 * document.documentElement.clientWidth / 1440;
        if (document.documentElement.clientWidth < 768) {
          this.qrSize = 80;
        }
      },
      ...mapActions(['saveUserNameAction']),

    },
    components: {
      QrcodeVue,
    },
  };
</script>

<style scoped lang="scss">

  @import "./../../assets/css/base";

  .my-qrcode {
    width: 100%;
    height: 100%;
    background-image: url("./../../assets/img/bg.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    position: relative;

    &-wrap {
      width: 100%;
      height: 100%;
      text-align: center;
      position: absolute;
      background: inherit;
      overflow: hidden;

      &-div {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;

        img {
          width: px2rem(834);
          height: px2rem(354);

          @media (max-width: 768px) {
            width: 70%;
            height: 200px;
          }
        }

        &-qr {
          position: absolute;
          z-index: 2;
          top: px2rem(66);
          left: 50%;
          transform: translateX(-50%);

          @media (max-width: 768px) {
            top: 40px;
          }

          p {
            color: chocolate;
            font-weight: bold;
            font-size: px2rem(24);
            margin-bottom: px2rem(20);
          }
        }
      }
    }

    &-wrap::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: inherit;
      background-attachment: fixed;
      filter: blur(3px);
    }

    &-wrap::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.25);
    }


  }
</style>
